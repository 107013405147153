import React from "react"

// import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://about.myles.systems//">
        myles-systems i.A. inQuam-Services
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const footers = [
  {
    title: "inQuam-Services",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Conferencen",
    description: ["inQuam-Conference"],
  },
  {
    title: "Resources",
    description: ["Interpretation", "Development"],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use", "GDPR"],
  },
]

const Footer = ({ classes }) => (
  <Container maxWidth="md" component="footer" className={classes.footer}>
    {console.log(classes, footers)}
    <Grid container spacing={4} justify="space-evenly">
      {footers.map(footer => (
        <Grid item xs={6} sm={3} key={footer.title}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {footer.title}
          </Typography>
          <ul>
            {footer.description.map(item => (
              <li key={item}>
                <Link href="#" variant="subtitle1" color="textSecondary">
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      ))}
    </Grid>
    <Box mt={5}>
      <Copyright />
    </Box>
  </Container>
)

export default Footer
