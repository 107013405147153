import React from "react"

import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
// import StyleLink from '@material-ui/core/Link';
import Container from "@material-ui/core/Container"
// import Box from '@material-ui/core/Box';

// import { Link } from "react-router-dom"

// import StarIcon from '@material-ui/icons/StarBorder';
import { useTranslation } from "react-i18next"

import Footer from "../../components/Footer"

// async function getLanguages() {
//   return fetch('locales/index.json').then((r) => r.json());
// }

const Welcome = ({ classes }) => {
  const { t } = useTranslation(["Welcome"], { useSuspense: false })
  //
  // const changeLanguage = (lng) => {
  //   t.changeLanguage(lng);
  // };

  return (
    <>
      <CssBaseline />

      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t("landing_welcome")}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          {t("landing_text")}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {[1, 2, 3].map(key => (
            <Grid item key={`${key}_card_key`} xs={12} md={4}>
              <Card>
                <CardHeader
                  title={t(`card${key}_head`)}
                  subheader={t(`card${key}_subhead`)}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  // action={}
                  className={classes.cardHeader}
                />
                <CardContent>
                  {/*
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  {t(`card${key}_text`)}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  /mo
                </Typography>
              </div>
              */}
                  <ul>
                    {[1, 2].map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        - {t(`card${key}_option_${line}`)}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={"outlined"} color="primary">
                    {t(`card${key}_button`)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer
       */}
      <Footer classes={classes} />

      {/* End footer */}
    </>
  )
}

export default Welcome
