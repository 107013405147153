import React, { useState, useEffect, Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import { useTranslation } from "react-i18next"

import { makeStyles,fade } from "@material-ui/core/styles"
// import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';  

import NavBar from "./components/NavBar"
import LiveView from "./views/LiveView"
import Welcome from "./views/Welcome"

import styles from "./styles.js"

import "./i18n"

async function getLanguages() {
  return fetch("locales/index.json").then(r => r.json())
}

const myStyles =styles(theme,fade)
const useStyles = makeStyles(myStyles)

const App = () => {
  const classes = useStyles()

  const { i18n } = useTranslation(["Welcome"], { useSuspense: false })
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  const [uiLanguages, setUiLanguages] = useState([])

  useEffect(() => {
    const test = async function () {
      let result = await getLanguages()
      setUiLanguages(result)
    }
    if (uiLanguages.length <= 1) {
      console.log("dont have the languages")
      test()
    }
  }, [uiLanguages])

  return (
    <Suspense fallback={<> </>}>
      <Router>
        <NavBar
          changeLanguage={changeLanguage}
          uiLanguages={uiLanguages}
          classes={classes}
        />
        {/* A <Switch> looks through its children <Route>s and
           renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Welcome classes={classes} />
          </Route>
          <Route exact path="/Live">
            <LiveView classes={classes} />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
