import React from "react"
import AppBar from "@material-ui/core/AppBar"
// import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Toolbar from "@material-ui/core/Toolbar"
// import StyleLink from "@material-ui/core/Link"
// import { fade } from '@material-ui/core/styles';

import { ReactComponent as Logo } from './inquam_logo.svg';

import LanguageSwitcher from "../LanguageSwitcher"
const NavBar = ({ classes, changeLanguage, uiLanguages }) => {
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          <Link to={"/"} style={{ textDecoration: "none", color: "#333" }}>
            <Logo />
          </Link>
        </Typography>
        {
          /*
        <nav>
          <StyleLink
            variant="button"
            color="textPrimary"
            className={classes.link}
            href={"https://booking.inquam-services.com"}
            target={"_new"}
          >
            Booking
          </StyleLink>
          <Link to={"/Live"}>
            <StyleLink
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Live
            </StyleLink>
          </Link>
          <Link to={"/Contact"}>
            <StyleLink
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Contact
            </StyleLink>
          </Link>
        </nav> */
        }
        <div className={classes.search}>
          <LanguageSwitcher
            changeLanguage={changeLanguage}
            uiLanguages={uiLanguages}
            iconPos={classes.searchIcon}
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
