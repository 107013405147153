import React from "react"
import Grid from "@material-ui/core/Grid"
// import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useTranslation } from "react-i18next"

function getFlagEmoji(countryCode) {
  return countryCode.toUpperCase().replace(/./g, char =>
      String.fromCodePoint(127397 + char.charCodeAt())
  );
}

const LanguageSwitcher = ({ uiLanguages, changeLanguage, iconPos }) => {
  // const { flag, lang, changeLanguage, languages } = props;
  const { i18n } = useTranslation(["Welcome"])
  let currentLand = "GB"
  let currentLang = []
  let flagEmoji
  currentLang = uiLanguages.filter(lang => {
    return lang.includes(i18n.language)
  })
  if (currentLang.length > 0) {
    currentLand = currentLang[0].split("-")[1]
    flagEmoji = getFlagEmoji(currentLand)
    console.log(flagEmoji);
  }

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <div className={iconPos}>
      {
        // <InputLabel htmlFor="outlined-flag-native-simple">
        // {flagEmoji}
        // </InputLabel>
        }
      </div>
      <Grid item>
        <Select
          native
          value={`${currentLang}`}
          onChange={e => changeLanguage(e.target.value)}
          label="Language"
          inputProps={{
            name: "Language",
            id: "outlined-flag-native-simple",
          }}

        >
          {uiLanguages.length > 0
            ? uiLanguages.map(lang => {
                let text = lang.split("-")
                let thisflagEmoji = getFlagEmoji(text[1])
                console.log(text);
                return (
                  <option key={lang} value={lang}>
                    {thisflagEmoji} {text[0]}
                  </option>
                )
              })
            : null}
        </Select>
      </Grid>
    </Grid>
  )
  // }
  // else {
  //   return(<p>no translations loaded</p>)
  // }
}

export default LanguageSwitcher
