import React from "react"
// import ReactDOM from "react-dom"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

// import BookingZoid from "../../components/BookingZoid"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}))

function iframe() { return{__html:'<iframe src="https://booking.inquam-services.com/#/booking" width="100%" height="100%" crossorigin></iframe>'}
};

const LiveView = () => {

  const [spacing] = React.useState(2)
  const classes = useStyles()

  const handleClick =(e) => {
    console.log(window.location.href);
     window.location.href ="https://booking.inquam-services.com"
     return null
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>

        <div onClick={handleClick}>
            <Grid key={"hej"} item xs={12}>
                <div

                  style={{
                    width: '100vw',
                    height: '100vh',
                    // pointerEvents: 'none'
                  }}

                  dangerouslySetInnerHTML={iframe()}
                />
            </Grid>
            </div>

        </Grid>
      </Grid>

    </Grid>
  )
}

              //
              //   <Paper className={classes.paper}  elevation={3}>
              // </Paper>
export default LiveView
